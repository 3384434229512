module.exports = {
  model: {
    id: '',
    title: '',
    scheduling_tolerance: '',
    authorized_services: [],
    vehicle_group: null,
    vehicles: [],
    start_date: new Date().toISOString(),
    end_date: null,
  },
  schema: {
    fields: [
      {
        type: 'custom-input',
        options: { uppercaseFirstLetter: true },
        label: 'Título',
        model: 'title',
        placeholder: 'Ex: Manutenção quadrimestral',
        required: true,
      },
      {
        type: 'custom-select-input',
        label: 'Critério de revisão',
        model: 'review_criteria',
        typeInput: 'input',
        placeholder: 'Valor',
        options: [],
        required: false,
      },
      {
        type: 'input',
        inputType: 'number',
        placeholder: 'Ex: 500',
        label: 'Tolerância',
        model: 'scheduling_tolerance',
        required: true,
      },
      {
        type: 'vueMultiSelect',
        label: 'Serviços (a serem executados)',
        model: 'authorized_services',
        required: true,
        multiSelect: false,
        placeholder: 'Selecione os serviços a serem executados',
        selectOptions: {
          multiple: true,
          trackBy: 'id',
          label: 'name',
          selectLabel: '',
          searchable: true,
          taggable: true,
          closeOnSelect: true,
          hideSelected: true,
        },
        values: [],
      },
      {
        type: 'vueMultiSelect',
        label: 'Agrupamento de veículos',
        model: 'vehicle_group',
        multiSelect: false,
        placeholder: 'Selecione um agrupamento',
        selectOptions: {
          multiple: false,
          trackBy: 'id',
          label: 'name',
          selectLabel: '',
          searchable: true,
          taggable: true,
          closeOnSelect: true,
          hideSelected: true,
        },
        onChanged: function(model, newVal, oldVal, field) {
          model.vehicles = newVal.vehicles
        },
        values: [],
      },
      {
        type: 'vueMultiSelect',
        label: 'Veículos',
        model: 'vehicles',
        required: true,
        multiSelect: false,
        placeholder: 'Selecione os veículos',
        selectOptions: {
          multiple: true,
          trackBy: 'id',
          label: 'plate',
          selectLabel: '',
          searchable: true,
          taggable: true,
          closeOnSelect: true,
          hideSelected: true,
        },
        values: [],
      },
      {
        type: 'input',
        inputType: 'date',
        label: 'Data de início',
        model: 'start_date',
        required: true,
      },
      {
        type: 'input',
        inputType: 'date',
        label: 'Data de término',
        model: 'end_date',
        required: false,
      },
    ],
  },
  columns: [
    { key: 'title', label: 'Título', filter: true },
    {
      key: 'review_criteria',
      label: 'Critério de revisão',
      relation: true,
      relationKey: 'name',
    },
    { key: 'review_criteria_value', label: 'Valor do critério', filter: true },
    { key: 'scheduling_tolerance', label: 'Tolerância', filter: true },
    {
      key: 'start_date',
      label: 'Data de início',
      dateMask: true,
      filter: true,
    },
    { key: 'end_date', label: 'Data de término', filter: true, dateMask: true },
    {
      key: 'status',
      label: 'Status',
      filter: true,
      status: true,
      statusType: [
        { name: 'Atrasada', color: '#d9534f' },
        { name: 'OK', color: '#5cb85c' },
      ],
    },
  ],
  viewDataTypes: [
    { key: 'title', label: 'Título', filter: true },
    { key: 'scheduling_tolerance', label: 'Tolerância', filter: true },
    {
      key: 'review_criteria',
      label: 'Critério de revisão',
      filter: true,
      relation: true,
      relationKey: 'name',
    },
    {
      key: 'review_criteria_value',
      label: 'Valor do critério de revisão',
      filter: true,
    },
    {
      key: 'start_date',
      label: 'Data de início',
      dateMask: true,
      filter: true,
    },
    { key: 'end_date', label: 'Data de término', dateMask: true },
    {
      key: 'authorized_services',
      label: 'Serviços (a serem executados)',
      array: true,
      arrayKey: 'name',
    },
    {
      key: 'vehicles',
      label: 'Veículos',
      array: true,
      multiple: true,
      keys: [{ label: 'Placa', key: 'plate' }],
    },
  ],
  viewDataMaintenanceTypes: [],
  exportFields: {
    PLACA: 'vehicle.plate',
  },
  schemaExportData: {
    fields: [
      {
        type: 'vueMultiSelect',
        label: 'Agendamento',
        model: 'scheduling',
        required: true,
        multiSelect: false,
        placeholder: 'Selecione um agendamento',
        selectOptions: {
          multiple: false,
          trackBy: 'id',
          label: 'title',
          selectLabel: '',
          searchable: true,
          taggable: true,
          closeOnSelect: true,
          hideSelected: true,
        },
        values: [],
      },
    ],
  },
}
