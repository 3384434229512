<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo agendamento</span>
    </button>
    <b-table
      :data="allMaintenanceSchedulings"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="(row) => $buefy.toast.open(`Visualizando os detalhes`)"
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="id"
        label="ID"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{ props.row.id }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{ props.row.id }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="title"
        label="Título"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.title.toUpperCase() }}</b-table-column
      >

      <b-table-column
        field="odometer"
        label="Quilometragem (periodicidade)"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.odometer }} Km</b-table-column
      >

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Título:</strong>
                <small> {{ props.row.title.toUpperCase() }}</small>
                <br />
                <strong>Quilometragem (periodicidade):</strong>
                <small> {{ props.row.odometer }} Km</small>
                <br />
                <strong>Veículos:</strong>
                <br />
                <span
                  v-for="(vehicle, index) of props.row.vehicles"
                  :key="index"
                >
                  - {{ vehicle.plate }}<br />
                </span>
                <br />
                <strong>Serviços Autorizados:</strong>
                <br />
                <span
                  v-for="(authorizedService, index) of props.row.authorized_services"
                  :key="index"
                >
                  - {{ authorizedService.name }}<br />
                </span>
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome *">
              <b-input v-model="model.title" required></b-input>
            </b-field>
            <b-field label="Quilometragem (periodicidade) *">
              <b-input
                type="number"
                v-model="model.odometer"
                required
              ></b-input>
            </b-field>
            <b-field label="Veículos">
              <multiselect
                v-model="model.vehicles"
                :options="allVehicles"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Selecione os veículos"
                label="plate"
                track-by="id"
                :preselect-first="false"
              ></multiselect>
            </b-field>
            <hr />
            <b-field label="Serviços Autorizados">
              <button
                class="button field is-info is-light"
                @click="addAuthorizedService"
              >
                <b-icon icon="plus"></b-icon>
                <span>Adicionar serviço autorizado</span>
              </button>
            </b-field>
            <b-field
              v-for="(authorizedService, index) of selectedAuthorizedServices"
              :key="index"
            >
              <b-collapse
                class="card"
                animation="slide"
                :open="isOpen == index"
                @open="isOpen = index"
              >
                <div
                  slot="trigger"
                  slot-scope="props"
                  class="card-header"
                  role="button"
                >
                  <p class="card-header-title">
                    {{
                      getAuthorizedServiceByID(
                        selectedAuthorizedServices[index].id
                      )
                        ? getAuthorizedServiceByID(
                            selectedAuthorizedServices[index].id
                          ).name.toUpperCase()
                        : 'Nenhum serviço selecionado'
                    }}
                  </p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <b-field label="Serviço Autorizado">
                      <model-list-select
                        :list="allAuthorizedServices"
                        :custom-text="authorizedServiceCustomOption"
                        placeholder="Selecione um serviço autorizado"
                        required
                        v-model="selectedAuthorizedServices[index].id"
                        option-value="id"
                      >
                      </model-list-select>
                    </b-field>
                  </div>
                </div>
                <footer class="card-footer">
                  <a
                    @click="removeAuthorizedService(index)"
                    class="card-footer-item"
                    >Remover</a
                  >
                </footer>
              </b-collapse>
            </b-field>
            <b-button type="button field is-info" class="mt-10" @click="save"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/maintenance-schedulings/store/service'
import MaintenanceScheduling from '../models/maintenance-scheduling'

export default {
  name: 'MaintenanceSchedulings',
  data() {
    return {
      model: MaintenanceScheduling.model,
      allAuthorizedServicesTemp: [],
      selectedAuthorizedServices: [],
      isModalVisible: false,
      isOpen: false,
      showDetailIcon: false,
      isCardModalActive: false,
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('maintenanceSchedulings', ['allMaintenanceSchedulings']),
    ...mapState('authorizedServices', ['allAuthorizedServices']),
    ...mapState('vehicles', ['allVehicles']),
  },
  methods: {
    ...mapActions('maintenanceSchedulings', ['getAllMaintenanceSchedulings']),
    ...mapActions('authorizedServices', ['getAllAuthorizedServices']),
    ...mapActions('vehicles', ['getAllVehicles']),
    authorizedServiceCustomOption(item) {
      return item.name
    },
    getAuthorizedServiceByID(id) {
      return this.allAuthorizedServices.find((x) => x.id === id)
    },
    async init() {
      await this.getAllMaintenanceSchedulings()
      await this.getAllAuthorizedServices()
      await this.getAllVehicles()

      console.log(this.allVehicles)

      this.allAuthorizedServicesTemp = this.allAuthorizedServices.map((x) => {
        return {
          ...x,
          price: parseFloat(x.price).toFixed(2),
        }
      })
    },
    async save() {
      const scheduling = { ...this.model }

      scheduling.authorizedServices = this.selectedAuthorizedServices.map(
        (x) => {
          x.value = x.value.replace('R$ ', '')
          x.value = x.value.replace(',', '')
          return {
            ...x,
          }
        }
      )

      scheduling.vehicles = scheduling.vehicles.map((x) => {
        return x.id
      })

      console.log('scheduling ', scheduling)

      try {
        const save = scheduling.id
          ? await service.updateAuthorizedService(scheduling)
          : await service.saveMaintenanceScheduling(scheduling)

        await this.$success('Agendamento')
        await location.reload(true)
      } catch (error) {
        console.log('error', error)
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o agendamento?')

        if (result.isConfirmed) {
          await service.deleteAuthorizedService(id)

          await this.$delete('Agendamento')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    onEdit(item) {
      this.model = { ...item }
    },
    addAuthorizedService() {
      this.selectedAuthorizedServices.push({
        id: null,
        value: 0,
      })
    },
    removeAuthorizedService(index) {
      this.selectedAuthorizedServices.splice(index, 1)
    },
  },
}
</script>

<style scoped></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>